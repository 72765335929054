<template>
  <v-col
    class="pa-0 pt-1"
    v-if="
      !productIngredientGroup.show_when_ingredient_selected ||
      selectedIngredientIds.indexOf(
        productIngredientGroup.show_when_ingredient_selected
      ) > -1
    "
  >
    {{selectedIngredientIds}}
    <v-row no-gutters class="px-3 px-md-4" align="center">
      <div class="text-body-2 text-md-subtitle-1 mr-2">
        {{ ingredientGroup.name }}
      </div>
    </v-row>
    <v-row dense class="px-3 py-2">
      <ingredient-tile
        v-for="(productIngredient, index) in productIngredients"
        :key="index"
        :with-image="withImage"
        :product-ingredient="productIngredient"
        :product-ingredient-group="productIngredientGroup"
        :selected-ingredient-ids="selectedIngredientIds"
      />
    </v-row>
  </v-col>
</template>

<script>
import IngredientTile from "./IngredientTile";

export default {
  name: "IngredientGroup",

  components: {
    IngredientTile,
  },

  props: {
    productIngredientGroup: Object,
    selectedIngredientIds: Array,
  },

  computed: {
    ingredientGroup() {
      return this.productIngredientGroup.ingredient_group;
    },

    productIngredients() {
      return this.productIngredientGroup.ingredients;
    },

    withImage() {
      for (let productIngredient of this.productIngredients) {
        if (productIngredient.ingredient.image) return true;
      }
      return false;
    },
  },
  methods: {},
};
</script>
