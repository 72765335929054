<template>
  <div class="px-1 mt-10">
    <v-row align="center" сlass="ma-0" style="margin-left: 0; margin-right: 0">
      <div class="text-h6 mr-3">Условие получения промокода</div>
      <v-divider />
    </v-row>
    <template v-if="item">
      <v-text-field
        v-if="item.type === $promocodeCondition.typeEnum.TEXT"
        v-model="item.for_earn.earn_limit"
        label="Лимит получения на пользователя"
        class="mb-3 mt-5"
        dense
        hide-details
        outlined
        type="number"
      ></v-text-field>
      <v-text-field
          v-if="item.type === $promocodeCondition.typeEnum.TEXT"
          v-model="item.for_earn.user_order_number"
          label="Порядковый номер заказа"
          class="mb-3 mt-5"
          dense
          hide-details
          outlined
          type="number"
      ></v-text-field>
      <v-text-field
          v-if="item.type === $promocodeCondition.typeEnum.TEXT"
          v-model="item.for_earn.user_order_number"
          label="Необходимое количество выполнения условий для получения промокода"
          class="mb-3 mt-5"
          dense
          hide-details
          outlined
          type="number"
      ></v-text-field>
      <v-text-field
        v-model="item.for_earn.min_order_sum"
        label="Минимальная сумма заказа в рублях"
        class="mb-3 mt-5"
        dense
        hide-details
        outlined
        type="number"
      ></v-text-field>
      <v-select
        v-model="item.for_earn.applicable_order_types"
        :items="$order.pickupTypes"
        item-value="value"
        item-text="label"
        class="mt-3"
        clearable
        dense
        hide-details
        outlined
        label="Тип заказа"
        multiple
      ></v-select>
      <v-checkbox
        v-model="item.for_earn.for_first_order"
        label="Первый заказ"
        hide-details
      />
      <v-btn depressed @click="productsSelectorDialog = true" class="mt-5"
        >Выбрать продукты
      </v-btn>
      <!--      {{ item?.for_earn }}-->
      <ProductsSelector
        v-model="productsSelectorDialog"
        @updateProductsData="updateProductsStructure($event)"
        :products-data="[{ products: item?.for_earn?.products_data || [] }]"
        :menu="productsStructure"
      />
    </template>
  </div>
</template>

<script>
import rules from "air-vue-model/rules";
import ProductsSelector from "@/admin/views/promocodes/coditionComoponents/ProductsSelector.vue";

export default {
  name: "Reward",
  components: { ProductsSelector },
  computed: {
    rules() {
      return rules;
    },
  },
  props: {
    item: Object,
  },
  data: () => {
    return {
      productsSelectorDialog: false,
      menuStructure: [],
      productsStructure: [],
    };
  },
  mounted() {
    void this.$product // todo вынести на уровень выше, чтобы не было двух запросов
      .sendGetSingle("structure", undefined, {
        active: true,
        show_in_app: true,
      })
      .then((res) => {
        this.productsStructure = res.result;
      });
  },
  methods: {
    updateProductsStructure(data) {
      const item = data[0] || { products: [] };
      if (!this.item.for_earn) this.item.for_earn = { products_data: [] };
      this.item.for_earn.products_data = item.products || [];
    },
  },
};
</script>

<style scoped></style>
