<template>
  <!--  :show-create-button="$auth.hasRight('roles__edit_information')"-->
  <div>
    <template-list :model="$userPromocode" :show-create-button="false">
      <template
        v-slot:actions
        v-if="
          $promocodeCondition.item?.type === $promocodeCondition.typeEnum.TEXT
        "
      >
        <v-btn
          depressed
          color="primary"
          @click="createTextCodesModelValue = true"
        >
          <div class="text-none">Выпустить промокоды</div>
        </v-btn>
      </template>
      <template v-slot="{ object, to, index }">
        <v-col class="pa-0 body-2 pointer">
          <router-link :to="to" style="width: 100%; height: 100%">
            <v-row no-gutters align="start link">
              <v-col class="font-weight-bold">
                {{ object.text }}
              </v-col>
            </v-row>
          </router-link>
        </v-col>
      </template>
    </template-list>
    <CreateTextCodesModal v-model="createTextCodesModelValue" />
  </div>
</template>

<script>
import CreateTextCodesModal from "@/admin/views/promocodes/CreateTextCodesModal.vue";

export default {
  name: "UserPromocodeList",
  components: { CreateTextCodesModal },
  data: () => {
    return {
      createTextCodesModelValue: false,
    };
  },
  created() {
    const id = this.$route.params.promocodeConditionId;
    if (!id) return;
    this.$userPromocode.filters.promocode_condition = id;
    void this.$userPromocode.loadList();
  },
};
</script>

<style scoped></style>
