<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Общая информация</div>
            <v-divider/>
            <v-btn
                v-if="$auth.hasRight('products__edit_information')"
                :loading="$airIngredient.loadings.save"
                @click="saveItem"
                depressed
                color="primary"
                class="ml-3 rounded-lg text-none body-2"
            >
                Сохранить
            </v-btn>
        </v-row>
        <v-row no-gutters>
            <ImageUploader
                title="Основное"
                class="mb-4"
                @input="uploadImage($event, 'image')"
                :image="item.image"
            ></ImageUploader>
        </v-row>
        <div>
            Наименование из учетной системы:
            <span class="font-weight-bold">{{
                    item.vendor_name || "Отсутствует"
                }}</span>
        </div>
        <v-text-field
            v-model="item.name"
            outlined
            hide-details
            dense
            label="Клиентское наименование"
            class="mt-4"
        />
        <v-text-field
            v-model="item.tracker_name"
            @input="item.tracker_name = $event || null"
            outlined
            hide-details
            dense
            label="Наименование для трекера"
            class="mt-4"
        />
        <v-text-field
            v-model="item.shelf_life"
            @input="item.shelf_life = $event || null"
            outlined
            hide-details
            dense
            type="number"
            label="Срок годности (часы)"
            class="mt-4"
        />
        <v-text-field
            v-model.number="item.sorting"
            outlined
            hide-details
            dense
            label="Сортировка"
            class="mt-4"
        />
        <v-checkbox
            class="pa-0 ma-0 mt-4"
            hide-details
            label="Отображать в приложении"
            v-model="item.show_in_app"
        ></v-checkbox>
        <v-checkbox
            class="ma-0 pa-0 mt-4"
            v-model="item.show_in_service"
            hide-details
            label="Отображать в сервисном"
        ></v-checkbox>
        <v-checkbox
            class="ma-0 pa-0 mt-4"
            v-model="item.show_in_tracker"
            hide-details
            label="Отображать в трекере"
        ></v-checkbox>
        <v-checkbox
            class="ma-0 pa-0 mt-4"
            v-model="item.show_in_app_order"
            hide-details
            label="Отображать в заказе гостю"
        ></v-checkbox>
        <v-checkbox
            class="ma-0 pa-0 my-4"
            v-model="item.dont_forget"
            hide-details
            label="Напоминать при выдаче"
        ></v-checkbox>
        <v-checkbox
            v-if="showAsProduct"
            class="ma-0 pa-0 my-4"
            v-model="item.as_product"
            hide-details
            label="Печатать отдельную этикетку"
        ></v-checkbox>
        <div class="text-h6 mr-3 mb-6">
            Изображения для внутреннего использования
        </div>
        <v-row no-gutters>
            <ImageUploader
                @delete="deleteSecondImage(index)"
                can-delete
                v-for="(el, index) in item.images"
                :key="index"
                :image="el.image"
                class="mb-4 mr-5"
                @input="uploadSecondImage($event, 'image', index)"
            ></ImageUploader>
            <ImageUploader
                class="mb-4"
                @input="uploadSecondImage($event, 'image', -1)"
            ></ImageUploader>
        </v-row>
    </v-col>
</template>

<script>
import ImageUploader from "@/admin/components/ImageUploader";
import imageUpload from "@/vendor/imageUpload";
import {cloneDeep, isEqual} from "lodash";
import {useEventListener} from "@/plugins/useEventListener";

export default {
    name: "IngredientItemBase",
    data: () => ({
        initialItem: null,
        eventsToClear: [],
    }),
    components: {
        ImageUploader,
    },
    mixins: [imageUpload],
    computed: {
        item() {
            return this.$airIngredient.item;
        },
        showAsProduct() {
            const product = this.$product.item
            if (!product) return false
            const pigs = product.product_ingredient_groups.filter(v => !!v.show_when_ingredient_selected)
            const found = pigs.find(v => !!v.ingredients.find(el => el.ingredient.id === this.item.id))
            return !!found
        }
    },
    mounted() {
        this.initialItem = cloneDeep(this.item);
        this.eventsToClear.push(
            this.$router.beforeEach((to, from, next) => {
                if (!isEqual(this.initialItem, this.item)) {
                    const answer = window.confirm(
                        "Если вы уйдете со страницы, то потеряете все несохраненные данные"
                    );
                    if (answer) {
                        if (this.eventsToClear.length) this.eventsToClear.map((v) => v());
                        next();
                    } else {
                        this.$store.commit("setProductDrawer", true);
                    }
                } else next();
            })
        );
        this.eventsToClear.push(
            useEventListener(window, "beforeunload", () => {
                if (!isEqual(this.initialItem, this.item)) {
                    return confirm();
                }
            })
        );
    },
    beforeDestroy() {
        if (this.eventsToClear.length) this.eventsToClear.map((v) => v());
    },
    methods: {
        async uploadImage(file, field) {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("type", field);
            const res = await this.$airIngredient.sendPutSingle(
                "upload_image",
                this.$airIngredient.item.id,
                formData
            );
            this.$airIngredient.item[field] = res.url;
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Изображение сохранено",
            });
        },
        async uploadSecondImage(file, field, index) {
            const formData = new FormData();
            formData.append("image", file);
            formData.append("type", field);
            formData.append("ingredient", this.item.id);
            let result = null;
            if (index > -1) {
                formData.append("id", this.item.images[index].id);
                result = await this.$ingredientImage.create(formData);
                this.item.images[index]["image"] = result["image"];
            } else {
                result = await this.$ingredientImage.create(formData);
                this.item.images.push(result);
            }
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Изображение сохранено",
            });
        },
        async deleteSecondImage(index) {
            const id = this.item.images[index].id;
            await this.$ingredientImage.delete(id);
            this.item.images.splice(index, 1);
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Изображение удалено",
            });
        },
        saveItem() {
            const {promise} = this.$airIngredient.updateOrCreate();
            promise.then((res) => {
                this.initialItem = cloneDeep(res);
                this.$store.commit("setSnackbar", {
                    color: "success",
                    text: "Данные сохранены",
                });
            });
        },
    },
};
</script>

<style scoped></style>
