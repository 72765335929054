<template>
  <v-dialog :value="value" @input="closeModal()">
    <v-card class="pa-3">
<!--      {{ currentData.products }}-->
      <v-text-field
          v-if="withDiscountInput"
        dense
        hide-details
        class="my-3"
        outlined
        type="number"
        v-model.number="currentData.discount_percent"
        label="Введите % скидки"
        :rules="[rules.required]"
      />
      <v-expansion-panels v-if="value">
        <v-expansion-panel v-for="(group, index) in menu" :key="index">
          <v-expansion-panel-header v-if="currentData.products">
            <v-row class="ma-0" align="center">
              <v-checkbox
                :input-value="
                  currentData.products.findIndex(
                    (v) =>
                      v.product_id === group.id && v.type === 'product_group'
                  ) > -1
                "
                readonly
                @click.stop="toggleGroup(group.id)"
                hide-details
                class="ma-0"
              />
              <b class="mt-2">{{ group.name }}</b>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              class="ma-0"
              v-for="(product, index) in group.items"
              :key="product.id"
              :class="{ 'mt-3': index }"
            >
              <v-checkbox
                :input-value="isSelected(product)"
                hide-details
                readonly
                class="ma-0"
                @click="toggle(product, product.group)"
              />
              <div
                class="mt-1"
                @click="openProduct(product)"
                style="cursor: pointer"
              >
                {{ product.name || product.vendor_name }} {{ product.id }}
              </div>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn color="primary" class="mt-3" @click="closeModal()">
        <div class="text-none">Применить</div>
      </v-btn>
    </v-card>
    <ProductDialog
      v-model="productModal"
      :product="productToOpen"
      :selected-ingredient-ids="productSelectedIngredientIds"
      @updateSelectedIngredients="
        updateSelectedIngredients($event, productToOpen)
      "
    />
  </v-dialog>
</template>

<script>
import { clone, cloneDeep } from "lodash";
import ProductDialog from "./ProductDialog.vue";
import rules from "air-vue-model/rules";

export default {
  name: "ProductsSelector",
  computed: {
    rules() {
      return rules;
    },
  },
  components: { ProductDialog },
  data: () => {
    return {
      currentData: { products: [] },

      productToOpen: null,
      productModal: false,
      productSelectedIngredientIds: [],
    };
  },
  props: {
    value: Boolean,
    productsData: Array, // [{products: []}]
    withDiscountInput: Boolean,
    menu: Array,
  },
  emits: ["input", "updateProductsData"],
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("input", false);
      this.$emit("updateProductsData", [this.currentData]);
    },
    isSelected(product) {
      const res = this.findProductInStructure(product.id);
      return !!res;
    },
    toggleGroup(groupId) {
      const groupIndex = this.currentData.products.findIndex(
        (v) => v.product_id === groupId && v.type === "product_group"
      );
      if (groupIndex > -1) this.currentData.products.splice(groupIndex, 1);
      else
        this.currentData.products.push({
          product_id: groupId,
          type: "product_group",
          children: [],
          excluded_children: [],
        });
    },
    toggle(product, groupId) {
      if (this.isSelected(product)) {
        const index = this.currentData.products.findIndex(
          (v) => v.product_id === product.id && v.type === "product"
        );
        if (index > -1) {
          this.currentData.products.splice(index, 1);
          return;
        }
        const foundGroup = this.findGroupInStructure(groupId);
        if (foundGroup) {
          const index = foundGroup.children.findIndex(
            (v) => v.product_id === product.id
          );
          if (index > -1) {
            foundGroup.children.splice(index, 1);
            if (!foundGroup.children.length) {
              this.toggleGroup(groupId);
            }
          }
        }
      } else {
        const foundGroup = this.findGroupInStructure(groupId);
        const productData = {
          product_id: product.id,
          type: "product",
          children: [],
          excluded_children: [],
        };
        if (foundGroup) foundGroup.children.push(productData);
        else {
          this.currentData.products.push({
            type: "product_group",
            product_id: groupId,
            children: [productData],
            excluded_children: [],
          });
        }
      }
    },
    openProduct(product) {
      void this.$product.loadItem(product.id).then((res) => {
        this.productToOpen = res;
        this.productModal = true;
        this.productSelectedIngredientIds =
          this.getSelectedIngredientIds(product);
      });
    },

    getSelectedIngredientIds(product) {
      for (const productData of this.currentData.products) {
        const res = this._getSelectedIngredientIds(productData, product);
        if (res !== null) return res;
      }
      return [];
    },

    _getSelectedIngredientIds(productData, product) {
      if (productData.type === "product") {
        if (productData.product_id === product.id) {
          const results = [];
          for (const el of productData.children) results.push(el.product_id);
          return results;
        }
      } else if (productData.children.length) {
        for (const el of productData.children) {
          const found = this._getSelectedIngredientIds(el, product);
          if (found) return found;
        }
      }
      return null;
    },
    updateSelectedIngredients(newSelectedIngredients, product) {
      let foundProduct = this.findProductInStructure(product.id);
      if (!newSelectedIngredients.length && !this.isSelected(product)) return;
      if (!foundProduct) {
        foundProduct = {
          type: "product",
          product_id: product.id,
          children: [],
          excluded_children: [],
        };
        let currentGroup = this.findGroupInStructure(product.group.id);
        if (!currentGroup) {
          currentGroup = {
            type: "product_group",
            product_id: product.group.id,
            children: [],
            excluded_children: [],
          };
          this.currentData.products.push(currentGroup);
        }
        currentGroup.children.push(foundProduct);
      }

      foundProduct.children = newSelectedIngredients.map((v) => {
        return {
          type: "ingredient",
          product_id: v,
        };
      });
    },
    findGroupInStructure(id) {
      const found = this.currentData.products.find(
        (v) => v.product_id === id && v.type === "product_group"
      );
      if (found) return found;
      return null;
    },
    findProductInStructure(productId, group = undefined) {
      if (group) {
        const res = this._findProductInStructure(group.children, productId);
        return res || null;
      } else {
        const found = this._findProductInStructure(
          this.currentData.products,
          productId
        );
        if (found) console.trace("return found", cloneDeep(found));
        else console.log(clone(this.currentData));
        if (found) return found;
      }
      return null;
    },
    _findProductInStructure(items, productId) {
      for (const item of items) {
        if (item.type === "product" && item.product_id === productId) {
          return item;
        } else if (item.type === "product_group") {
          const res = this._findProductInStructure(item.children, productId);
          if (res) return res;
        }
      }
      return null;
    },
  },
  watch: {
    value(v) {
      if (v) {
        console.log("watch", cloneDeep(this.productsData));
        this.currentData = cloneDeep(this.productsData[0] || { products: [] });
      }
    },
  },
};
</script>

<style scoped></style>
