<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    @click:outside="$emit('input', false)"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="700"
    scrollable
  >
    <v-card
      v-if="product"
      color="background"
      max-height="600"
      style="overflow-y: auto"
    >
<!--      {{this.currentSelectedIngredientIds}}-->
      <v-row v-if="$vuetify.breakpoint.smAndDown" no-gutters>
        <div class="pt-2 pl-1">
          <v-btn icon @click="close">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
      </v-row>
      <v-card class="rounded-lg elevation-1 ma-3">
        <v-row align="center" no-gutters>
          <v-col class="pa-3" cols="3">
            <v-img :src="image" aspect-ratio="1" contain />
          </v-col>
          <v-col class="pa-3" cols="9">
            <div v-if="$store.state.showAllInfo" class="font-weight-medium">
              id: {{ product.id }}
            </div>
            <div
              class="text-subtitle-1 text-md-h5 font-weight-medium"
              v-text="product.name"
            />
          </v-col>
        </v-row>
        <v-col v-if="product.description" class="pa-0">
          <v-row justify="center" no-gutters>
            <div
              class="pb-3 text-caption text--secondary cursor-pointer"
              @click="showDescription = !showDescription"
            >
              Показать описание
            </div>
          </v-row>
          <div
            v-if="showDescription"
            class="pa-3 text-body-2"
            style="white-space: pre-line"
          >
            {{ product.description }}
          </div>
        </v-col>
      </v-card>
      <ingredient-group
        v-for="(ingredientGroup, index) in productIngredientGroups"
        :key="index"
        :product-ingredient-group="ingredientGroup"
        :selected-ingredient-ids="currentSelectedIngredientIds"
      />
      <div style="min-height: 65px" />
    </v-card>
  </v-dialog>
</template>

<script>
import IngredientGroup from "./IngredientGroup";
import { cloneDeep } from "lodash";

export default {
  name: "ProductDialog",

  components: {
    IngredientGroup,
  },

  props: {
    value: Boolean,
    product: Object,
    selectedIngredientIds: Array,
  },

  emits: ["input", "updateSelectedIngredients"],

  data: () => ({
    showDescription: false,

    currentSelectedIngredientIds: [],
  }),

  computed: {
    productIngredientGroups() {
      return this.product.product_ingredient_groups;
    },

    image() {
      const notFoundImage = "/static/not-found.png";
      if (!this.product) return notFoundImage;
      return this.product.image || notFoundImage;
    },

    selectedProductIngredients() {
      return this.$store.state.selectedProductIngredients;
    },
  },

  methods: {},

  created() {},

  watch: {
    value(v) {
      if (v) {
        this.currentSelectedIngredientIds = cloneDeep(
          this.selectedIngredientIds
        );
      } else {
        this.$emit(
          "updateSelectedIngredients",
          this.currentSelectedIngredientIds
        );
      }
    },
  },
};
</script>
