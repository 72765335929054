<template>
  <!--  :show-actions="$auth.hasRight('nomenclature__edit_information')"-->
  <template-item
    v-slot="{ object, rules }"
    :model="$promocodeCondition"
    object-key-for-title="name"
    title-prepend="Условие промокода / "
    :show-actions="$route.name === 'promocodeConditionItem'"
  >
    <v-row no-gutters align="center" class="mb-5 mx-0" v-if="object.id">
      <v-btn
        @click="$router.replace({ name: 'promocodeConditionItem' })"
        :color="$route.name === 'promocodeConditionItem' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Общая информация
      </v-btn>
      <v-btn
        @click="$router.replace({ name: 'promocodeConditionPromocodesList' })"
        :color="
          $route.name === 'promocodeConditionPromocodesList' ? 'primary' : ''
        "
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Список промокодов
      </v-btn>
    </v-row>
    <router-view />
  </template-item>
</template>

<script>
export default {
  name: "PromocodeConditionPage",
  data: () => ({}),
  methods: {},
};
</script>

<style scoped></style>
