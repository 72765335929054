<template>
  <v-col cols="6" md="3">
    <v-card class="rounded-lg pa-3 elevation-1" height="100%">
      <div v-if="$store.state.showAllInfo" class="mb-1 caption"></div>
      <div>
        <div
          @click="toggle"
          class="cursor-pointer fill-height d-flex flex-column justify-space-between"
        >
          <v-row no-gutters align="start" justify="space-between">
            <v-icon :size="20" color="primary">
              {{
                isSelected
                  ? "mdi-check-circle-outline"
                  : "mdi-checkbox-blank-circle-outline"
              }}
            </v-icon>
            <v-img
              v-if="withImage"
              height="40"
              width="40"
              :src="image"
              aspect-ratio="1"
              contain
            />
          </v-row>
          <div class="d-flex align-center justify-center mt-2">
            <div class="text-body-2 noselect text-center">
              {{ ingredient.name }}
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "IngredientTile",

  props: {
    productIngredient: Object,
    productIngredientGroup: Object,
    selectedIngredientIds: Array,
    withImage: Boolean,
  },

  data: () => ({
    isChanging: false,
  }),

  computed: {
    ingredient() {
      return this.productIngredient.ingredient;
    },
    image() {
      return this.ingredient.image || "/media/not-found.png";
    },

    isSelected() {
      return this.selectedIngredientIds.includes(
        this.productIngredient.ingredient.id
      );
    },
  },

  methods: {
    toggle() {
      if (this.isSelected) {
        const index = this.selectedIngredientIds.findIndex(
          (v) => v === this.productIngredient.ingredient.id
        );
        if (index > -1) this.selectedIngredientIds.splice(index, 1);
      } else {
        this.selectedIngredientIds.push(this.productIngredient.ingredient.id);
      }
    },
  },
  watch: {},
};
</script>
