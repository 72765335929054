<template>
  <div>
    <v-select
      v-model="object.type"
      @input="onTypeChange"
      class="mb-3"
      dense
      hide-details
      label="Тип"
      outlined
      :items="$promocodeCondition.typesList"
      item-text="label"
      item-value="value"
    />
    <v-select
      v-model="object.status"
      class="mb-3"
      dense
      hide-details
      label="Статус"
      outlined
      :items="$promocodeCondition.statusList"
      item-text="label"
      item-value="value"
    />
    <v-text-field
      v-model="object.name"
      class="mb-3 mt-5"
      dense
      hide-details
      label="Наименование"
      outlined
      :rules="[rules.required]"
    ></v-text-field>
    <!--  todo доработать products selector, чтобы он возвращал норм структуру   -->
    <ForEarn
      :item="object"
      v-if="object.type === $promocodeCondition.typeEnum.DEFAULT"
    />
    <ForApply :item="object" />
    <Reward :item="object" />
  </div>
</template>

<script>
import Reward from "@/admin/views/promocodes/coditionComoponents/Reward.vue";
import ForApply from "@/admin/views/promocodes/coditionComoponents/ForApply.vue";
import ForEarn from "@/admin/views/promocodes/coditionComoponents/ForEarn.vue";
import { cloneDeep } from "lodash";
import rules from "air-vue-model/rules";

export default {
  name: "PromocodeConditionItem",
  components: { Reward, ForApply, ForEarn },
  data: () => ({}),
  methods: {
    onTypeChange(newType) {
      if (
        newType === this.$promocodeCondition.typeEnum.TEXT &&
        this.$promocodeCondition.item
      ) {
        this.$promocodeCondition.item.for_apply.apply_limit = 1;
        this.$promocodeCondition.item.for_earn = cloneDeep(
          this.$promocodeCondition.default.for_earn
        );
      }
    },
  },
  computed: {
    rules() {
      return rules
    },
    object() {
      return this.$promocodeCondition.item;
    },
  },
};
</script>

<style scoped></style>